import logo from './logo.svg';
import './App.css';
import Home from './views/Home';
import { Container } from 'react-bootstrap';
import Education from './views/Education';
import Professionnel from './views/Professionnel';
import Competences from './views/Competences';

function App() {
  return (
    <div className="App">
      <Container>
        <Home />
        <Competences />
        <Professionnel />
        <Education />
      </Container>
    </div>
  );
}

export default App;
