import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsSplitUpAndLeft, faBrain, faCode, faComment, faDatabase, faDiagramProject, faGraduationCap, faHome, faImage, faPaw, faSuitcase, faTerminal } from '@fortawesome/free-solid-svg-icons'
import { faBrave, faCss3, faGit, faGitAlt, faHtml5, faJava, faPhp, faPython, faReact } from "@fortawesome/free-brands-svg-icons"
import { Badge, Col, Row } from 'react-bootstrap';

function Competences() {
    return (
        <>
            <h2 id="competences"><FontAwesomeIcon icon={faCode} /> Competences</h2>
            <p>
                <Badge className='python'><FontAwesomeIcon icon={faPython} /> Python</Badge>
                <Badge><FontAwesomeIcon icon={faHtml5} /> HTML5</Badge>
                <Badge><FontAwesomeIcon icon={faCss3} /> CSS3</Badge>
                <Badge><FontAwesomeIcon icon={faReact} /> React</Badge>
                <Badge><FontAwesomeIcon icon={faJava} /> Java</Badge>
                <Badge><FontAwesomeIcon icon={faPhp} /> PHP</Badge>
                <Badge><FontAwesomeIcon icon={faTerminal} /> Shell</Badge>
                <Badge><FontAwesomeIcon icon={faGitAlt} /> Git</Badge>
            </p>
            <p>
                <Badge><FontAwesomeIcon icon={faBrain} /> Machine learning</Badge>
                <Badge><FontAwesomeIcon icon={faDatabase} /> Analyse de données</Badge>
                <Badge><FontAwesomeIcon icon={faComment} /> Traitement Automatique des langues</Badge>
                <Badge><FontAwesomeIcon icon={faImage} /> Vision par ordinateur</Badge>
                <Badge><FontAwesomeIcon icon={faDiagramProject} /> Gestion de projet</Badge>
                <Badge><FontAwesomeIcon icon={faArrowsSplitUpAndLeft} /> Méthodes agiles</Badge>
            </p>
        </>
    );
}

export default Competences;