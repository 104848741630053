import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCode, faGraduationCap, faHome, faPaw, faSuitcase } from '@fortawesome/free-solid-svg-icons'

function Header() {
    return (
        <Navbar expand="lg" sticky="top" bg="dark" data-bs-theme="dark">
            <Container>
                <Navbar.Brand href="#home">Cédric Maigrot</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="#accueil"><FontAwesomeIcon icon={faHome} /> Accueil</Nav.Link>
                        <Nav.Link href="#competences"><FontAwesomeIcon icon={faCode} /> Compétences</Nav.Link>
                        <Nav.Link href="#professionnel"><FontAwesomeIcon icon={faSuitcase} /> Professionnel</Nav.Link>
                        <Nav.Link href="#education"><FontAwesomeIcon icon={faGraduationCap} /> Education</Nav.Link>
                        <Nav.Link href="#passions"><FontAwesomeIcon icon={faPaw} /> Passions</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;