import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAward, faCalendarDays, faCrown, faGraduationCap, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { Col, Row } from 'react-bootstrap';

function Education() {
    return (
        <>
            <h2 id="education"><FontAwesomeIcon icon={faGraduationCap} /> Education</h2>
            <Row>
                {/* Master */}
                <Col md={8}>
                    <img alt="Université de Montpellier II" className="w-100" src="ur.jpg" />
                </Col>
                <Col md={4}>
                    <h3>Thèse de doctorat</h3>
                    <p className="text-left">
                        <ul>
                            <li><FontAwesomeIcon fixedWidth icon={faLocationDot} /> Université de Rennes</li>
                            <li><FontAwesomeIcon fixedWidth icon={faCalendarDays} /> Oct. 2015 - Avril 2019</li>
                            <li><FontAwesomeIcon fixedWidth style={{ "color": "gold" }} icon={faCrown} /> Félicitations du jury</li>
                        </ul>
                    </p>
                </Col>
                {/* Master */}
                <Col md={2}>
                    <img alt="Université de Montpellier II" className="w-100" src="um.jpg" />
                </Col>
                <Col md={4}>
                    <h3>Master DECOL</h3>
                    <p className="text-left">
                        <ul>
                            <li><FontAwesomeIcon fixedWidth icon={faLocationDot} /> Université de Montpellier</li>
                            <li><FontAwesomeIcon fixedWidth icon={faCalendarDays} /> Sept. 2013 - Juin 2015</li>
                            <li><FontAwesomeIcon fixedWidth style={{ "color": "gold" }} icon={faCrown} /> Major de promotion</li>
                        </ul>
                    </p>
                </Col>
                {/* Licence */}
                <Col md={2}>
                    <img alt="Université de Montpellier II" className="w-100" src="um.jpg" />
                </Col>
                <Col md={4}>
                    <h3>Licence informatique</h3>
                    <p className="text-left">
                        <ul>
                            <li><FontAwesomeIcon fixedWidth icon={faLocationDot} /> Université de Montpellier</li>
                            <li><FontAwesomeIcon fixedWidth icon={faCalendarDays} /> Sept. 2012 - Juin 2013</li>
                            <li><FontAwesomeIcon fixedWidth style={{ "color": "gold" }} icon={faAward} /> Obtention</li>
                        </ul>
                    </p>
                </Col>
                {/* DUT */}
                <Col md={2}>
                    <img alt="IUT de Montpellier" className="w-100" src="iut.jpg" />
                </Col>
                <Col md={4}>
                    <h3>DUT Informatique</h3>
                    <p className="text-left">
                        <ul>
                            <li><FontAwesomeIcon fixedWidth icon={faLocationDot} /> IUT de Montpellier</li>
                            <li><FontAwesomeIcon fixedWidth icon={faCalendarDays} /> Sept. 2010 - Juin 2012</li>
                            <li><FontAwesomeIcon fixedWidth style={{ "color": "gold" }} icon={faAward} /> TOP 10 de promotion</li>
                        </ul>
                    </p>
                </Col>
                {/* Bac */}
                <Col md={2}>
                    <img alt="Lycée Dhuoda" className="w-100" src="dhuoda.jpg" />
                </Col>
                <Col md={4}>
                    <h3>Bac tech. STI Electronique</h3>
                    <p className="text-left">
                        <ul>
                            <li><FontAwesomeIcon fixedWidth icon={faLocationDot} /> Lycée Dhuoda, Nîmes (30)</li>
                            <li><FontAwesomeIcon fixedWidth icon={faCalendarDays} /> Sept. 2006 - Juin 2010</li>
                            <li><FontAwesomeIcon fixedWidth style={{ "color": "gold" }} icon={faCrown} /> Major d'établissement</li>
                        </ul>
                    </p>
                </Col>
            </Row>
        </>
    );
}

export default Education;