import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard, faAward, faCalendarDays, faCrown, faGraduationCap, faLocationDot, faSuitcase, faUser, faUserGraduate } from '@fortawesome/free-solid-svg-icons'
import { Col, Row } from 'react-bootstrap';

function Professionnel() {
    return (
        <>
            <h2 id="professionnel"><FontAwesomeIcon icon={faSuitcase} /> Professionnel</h2>
            <Row>
                {/* OSE */}
                <Col md={2}>
                    <img alt="OSE Engineering" className="w-100" src="ose.png" />
                </Col>
                <Col md={4}>
                    <h3>R&D Engineer</h3>
                    <p className="text-left">
                        <ul>
                            <li><FontAwesomeIcon fixedWidth icon={faAddressCard} /> OSE Engineering</li>
                            <li><FontAwesomeIcon fixedWidth icon={faLocationDot} /> Télétravail complet</li>
                            <li><FontAwesomeIcon fixedWidth icon={faCalendarDays} /> Mai 2021 - Aujourd'hui</li>
                        </ul>
                    </p>
                </Col>
                {/* AMOS */}
                <Col md={2}>
                    <img alt="AMOS" className="w-100" src="amos.jpg" />
                </Col>
                <Col md={4}>
                    <h3>Intervenant informatique</h3>
                    <p className="text-left">
                        <ul>
                            <li><FontAwesomeIcon fixedWidth icon={faAddressCard} /> AMOS</li>
                            <li><FontAwesomeIcon fixedWidth icon={faLocationDot} /> Rennes (35)</li>
                            <li><FontAwesomeIcon fixedWidth icon={faCalendarDays} /> Sept. 2020 - Déc. 2020</li>
                        </ul>
                    </p>
                </Col>
                {/* INRIA */}
                <Col md={2}>
                    <img alt="INRIA Rennes Bretagne Loire" className="w-100" src="inria.jpg" />
                </Col>
                <Col md={4}>
                    <h3>Ingénieur de recherche</h3>
                    <p className="text-left">
                        <ul>
                            <li><FontAwesomeIcon fixedWidth icon={faAddressCard} /> INRIA Rennes Bretagne Loire</li>
                            <li><FontAwesomeIcon fixedWidth icon={faLocationDot} /> Rennes (35)</li>
                            <li><FontAwesomeIcon fixedWidth icon={faCalendarDays} /> Mai 2019 - Déc. 2019</li>
                        </ul>
                    </p>
                </Col>
                {/* INRIA */}
                <Col md={2}>
                    <img alt="INRIA Rennes Bretagne Loire" className="w-100" src="inria_dga.jpg" />
                </Col>
                <Col md={4}>
                    <h3>Doctorant</h3>
                    <p className="text-left">
                        <ul>
                            <li><FontAwesomeIcon fixedWidth icon={faAddressCard} /> INRIA Rennes Bretagne Loire</li>
                            <li><FontAwesomeIcon fixedWidth icon={faAddressCard} /> Direction Générale de l'Armement</li>
                            <li><FontAwesomeIcon fixedWidth icon={faLocationDot} /> Rennes (35)</li>
                            <li><FontAwesomeIcon fixedWidth icon={faCalendarDays} /> Sept. 2015 - Avril 2019</li>
                        </ul>
                    </p>
                </Col>
                {/* LIRMM */}
                <Col md={2}>
                    <img alt="LIRMM" className="w-100" src="lirmm.jpg" />
                </Col>
                <Col md={4}>
                    <h3>Stagiaire</h3>
                    <p className="text-left">
                        <ul>
                            <li><FontAwesomeIcon fixedWidth icon={faAddressCard} /> LIRMM</li>
                            <li><FontAwesomeIcon fixedWidth icon={faLocationDot} /> Montpellier (34)</li>
                            <li><FontAwesomeIcon fixedWidth icon={faCalendarDays} /> Fév. 2015 - Juillet 2015</li>
                        </ul>
                    </p>
                </Col>
            </Row>
        </>
    );
}

export default Professionnel;