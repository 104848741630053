import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap, faHome, faPaw, faSuitcase } from '@fortawesome/free-solid-svg-icons'
import { Col, Row } from 'react-bootstrap';

function Home() {
    return (
        <>
            <h2 id="accueil"><FontAwesomeIcon icon={faHome} /> Accueil</h2>
            <Row>
                <Col md={3}>
                    <img alt="Cédric Maigrot" className="w-100 profile-photo" src="profilepic.jpg" />
                </Col>
                <Col md={9} id="about">
                    <h3>A propos</h3>
                    <p>
                        Actuellement Data Scientist chez <a href="https://ose-engineering.fr/" target="_blank">OSE Engineering</a>.
                    </p>
                    <p>
                        Après la réalisation d'une thèse en informatique à l'<a href="https://www.irisa.fr/" target='_blank'>IRISA</a>,
                        j'ai souhaité quitter le monde académique pour mettre en application
                        mes connaissances pour des données et sujets réels.
                    </p>
                </Col>
            </Row>
        </>
    );
}

export default Home;